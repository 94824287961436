import { Aura } from 'primeng/themes/aura';
import { definePreset } from 'primeng/themes';

const customPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '#e2f7f9',
      100: '#b8ebf0',
      200: '#8edde6',
      300: '#69cfdc',
      400: '#58c5d3',
      500: '#4695a0', //#52bbcc
      600: '#4dabb9',
      700: '#4695a0',
      800: '#408188',
      900: '#365e5f',
      950: '#365e5f',
    },
  },
});

export const customTheme = {
  preset: customPreset,
  options: {
    darkModeSelector: '.dark',
  },
};
